import {IPlugin} from '../../types'

export const PLUGINS: IPlugin[] = [
  {
    name: 'VS Code',
    type: 'vscode',
    icon: 'vscode'
  },
  {
    name: 'IntelliJ IDEA',
    type: 'jetbrains',
    icon: 'intellijidea'
  },
  {
    name: 'Chrome',
    type: 'chrome',
    icon: 'chrome'
  },
  {
    name: 'Android Studio',
    type: 'jetbrains',
    icon: 'androidstudio'
  },
  {
    name: 'Wakatime',
    type: 'wakatime-import',
    icon: 'wakatime'
  },
  {
    name: 'Cursor',
    type: 'vscode',
    icon: 'cursor'
  },
  {
    name: 'AppCode',
    type: 'jetbrains',
    icon: 'appcode'
  },
  {
    name: 'Aqua',
    type: 'jetbrains',
    icon: 'aqua'
  },
  {
    name: 'CLion',
    type: 'jetbrains',
    icon: 'clion'
  },
  {
    name: 'DataGrip',
    type: 'jetbrains',
    icon: 'datagrip'
  },
  {
    name: 'DataSpell',
    type: 'jetbrains',
    icon: 'dataspell'
  },
  {
    name: 'GoLand',
    type: 'jetbrains',
    icon: 'goland'
  },
  {
    name: 'MPS',
    type: 'jetbrains',
    icon: 'mps'
  },
  {
    name: 'PhpStorm',
    type: 'jetbrains',
    icon: 'phpstorm'
  },
  {
    name: 'PyCharm',
    type: 'jetbrains',
    icon: 'pycharm'
  },
  {
    name: 'Rider',
    type: 'jetbrains',
    icon: 'rider'
  },
  {
    name: 'RubyMine',
    type: 'jetbrains',
    icon: 'rubymine'
  },
  {
    name: 'RustRover',
    type: 'jetbrains',
    icon: 'rustrover'
  },
  {
    name: 'WebStorm',
    type: 'jetbrains',
    icon: 'webstorm'
  }
]
