import React, {FC} from 'react'
import {Flex, Text, Highlight, Image, Link} from '@chakra-ui/react'
import instructionImg from 'assets/instructions/chrome/instruction1.png'
import linkImg from 'assets/instructions/chrome/instruction2.png'
import {useTranslation} from 'react-i18next'

const highlightStyle = {px: '2', py: '1', borderRadius: '6px', bg: 'accent.1-30', color: 'white.2'}
const highlightTextStyle = {color: 'white.1'}

export const Chrome: FC = () => {
  const {t} = useTranslation()

  return (
    <Flex minHeight={'180px'}>
      <Flex flexDirection={'column'}>
        <Text mb={'20px'} textStyle={'lg'} color={'white.1'}>
          {t('chromePlugin.title')}
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          {t('chromePlugin.subtitle')}
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query={['Settings', 'Plugin']} styles={highlightStyle}>
            {t('chromePlugin.stepOne') as string}
          </Highlight>
        </Text>
        <Image w={'100%'} mb={'6px'} src={instructionImg} />
        
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query="Nau" styles={highlightTextStyle}>
            {t('chromePlugin.stepTwo') as string}
          </Highlight>
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query="Log in" styles={highlightStyle}>
            {t('chromePlugin.stepThree') as string}
          </Highlight>
        </Text>
        <Flex w={'100%'} justify={'center'}>
          <Image maxW={['300px', '300px', '500px']} src={linkImg} />
        </Flex>

        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          {t('chromePlugin.stepFour') as string}
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          {t('chromePlugin.stepFive') as string}
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          If you have any problems, you can reach us via email{' '}
          <Link href={'mailto:support@nautime.io'}>
            <Text display={'inline'} color={'accent.1'}>
              support@nautime.io
            </Text>
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}
