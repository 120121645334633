import React, {FC} from 'react'
import {Divider, Flex, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {PluginIcon} from 'components'
import {IPlugin} from '@kaef/common/types'
import {paths} from 'shared/constants/paths'
import {useLocation, useNavigate} from 'react-router-dom'
import {PLUGINS} from '@kaef/common/ui/constants/plugins'

const Plugins: FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const onNavToPlugin = (plugin: IPlugin) => {
    navigate(`${paths.instructions.path}/${plugin.type}`, {state: {backgroundLocation: location}})
  }

  return (
    <Flex w={'100%'} justifyContent={'center'} pt={'40px'} pb={'82px'}>
      <Flex
        p={'32px 24px'}
        w={'100%'}
        maxW={'953px'}
        minH={'100%'}
        flexDirection={'column'}
        bgColor={'white.1-05'}
        borderWidth={'1px'}
        borderColor={'black.3-40'}
        borderRadius={'8px'}>
        <Text textStyle={'lg'}>{t('plugins.title')}</Text>
        <Divider m={'32px 0'} w={'100%'} orientation={'horizontal'} bgColor={'black.4'} />
        <Flex w={'100%'} justifyContent={'center'}>
          <Flex alignItems={'center'} w={'100%'} maxW={'650px'} gap={'12px'} flexWrap={'wrap'}>
            {PLUGINS.map((item) => {
              return (
                <Flex
                  flexDirection={'column'}
                  borderRadius={'8px'}
                  borderWidth={'1px'}
                  cursor={'pointer'}
                  borderColor={'black.5-40'}
                  backgroundColor={'white.1-05'}
                  display={'flex'}
                  minHeight={'140px'}
                  key={item.name}
                  w={'calc(25% - 9px)'}
                  onClick={() => onNavToPlugin(item)}
                  _hover={{
                    borderColor: 'accent.1',
                    backgroundColor: 'accent.1-05'
                  }}
                  justifyContent={'center'}
                  alignItems={'center'}>
                  <PluginIcon type={item.icon} width={'40px'} height={'40px'} />
                  <Text textStyle={'md'} align={'center'} mt={'8px'}>
                    {item.name}
                  </Text>
                </Flex>
              )
            })}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Plugins
