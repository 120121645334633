import React, {FC, useState} from 'react'
import {Center, Divider, Flex, Text, CircularProgress as ChakraCircularProgress} from '@chakra-ui/react'
import {ERange} from '@kaef/common/types'
import {Avatar, Button, ButtonGroup, CircularProgress, IconButton, Stat, Alert} from 'components'
import {useTranslation} from 'react-i18next'
import {logChangeFriendsRange} from 'services/analytics/analytics'
import {useNavigate, useParams} from 'react-router-dom'
import {overviewDateByRangeSelector, useFriendsStore} from 'stores'
import {api} from 'services/api'
import {prepareFriendsRequestData, secondsToString} from '@kaef/common/utils/helpers'
import {FriendsRangeSwitcher} from '../../../../components/FriendsMain/components/FriendsRangeSwitcher'
import {paths} from 'shared/constants/paths'
import {useQueryClient} from '@tanstack/react-query'
import {friendsKeys} from '../../../../../../../../../../common/api/friends'

interface IProps {}

export const FriendMain: FC<IProps> = ({}) => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [range, setRange] = useFriendsStore((state) => [state.range, state.setRange])
  const {id} = useParams()

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const friendsState = useFriendsStore((state) => state)
  const date = overviewDateByRangeSelector(range, friendsState)

  const friendsQuery = api.useFriendsQuery()
  const friendQuery = api.useFriendStatsQuery(prepareFriendsRequestData({day: date, range, id}), {enabled: !!id})

  const friend = friendsQuery.data?.find((f) => f.id === id)

  const removeFriendMutation = api.useRemoveFriendMutation()

  const rangeOptions = [
    {
      label: t('overview.ranges.d'),
      value: ERange.DAY
    },
    {
      label: t('overview.ranges.w'),
      value: ERange.WEEK
    },
    {
      label: t('overview.ranges.m'),
      value: ERange.MONTH
    },
    {
      label: t('overview.ranges.y'),
      value: ERange.YEAR
    }
  ]

  const onRemove = () => {
    if (!id) {
      return
    }
    removeFriendMutation.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(friendsKeys.all())
        navigate(paths.friends.path)
      }
    })
  }

  const changeRange = (value: ERange) => {
    logChangeFriendsRange(value)
    setRange(value)
  }

  const onBack = () => {
    navigate(-1)
  }

  return (
    <>
      <Flex
        width={'100%'}
        flexDirection={'column'}
        bgColor={'white.1-05'}
        borderWidth={'1px'}
        borderColor={'black.5-40'}
        pb={'24px'}
        pl={'24px'}
        pr={'24px'}
        pt={'14px'}
        gap={'20px'}
        borderRadius={'8px'}>
        <Flex mt={'8px'} justifyContent={'space-between'} alignItems={'center'}>
          <Flex alignItems={'center'} gap={'12px'}>
            <IconButton onClick={onBack} icon={'arrowBack'} color={'white.3'} aria-label={'back'} variant={'ghost'} />
            <Avatar size={'lg'} name={friend?.name} />
            <Flex flexDirection={'column'}>
              <Text textStyle={'md'}>{friend?.name}</Text>
              <Text textStyle={'sm'} textColor={'white.3'}>
                {friend?.email}
              </Text>
            </Flex>
          </Flex>
          <Button w={'80px'} onClick={() => setIsConfirmDeleteOpen(true)} variant={'secondary'}>
            {t('friends.remove')}
          </Button>
        </Flex>
        <Divider />
        <Flex
          w={'100%'}
          justifyContent={'space-between'}
          flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']}>
          <FriendsRangeSwitcher range={range} />
          <ButtonGroup
            options={rangeOptions}
            value={range}
            onChange={changeRange}
            containerProps={{
              w: ['100%', '100%', '100%', '100%', '368px'],
              mb: ['12px', '12px', '12px', '12px', 0],
              flexShrink: 0,
              bg: 'black.4'
            }}
          />
        </Flex>

        <Flex mt={'30px'} mb={'40px'} w={'100%'} justifyContent={'center'} alignItems={'center'}>
          {friendQuery.isLoading && (
            <Center>
              <ChakraCircularProgress isIndeterminate color={'accent.1'} />
            </Center>
          )}
          {!friendQuery.isLoading && (
            <Flex
              w={'100%'}
              flexDirection={['column-reverse', 'column-reverse', 'row']}
              alignItems={['center', 'center', 'flex-start']}
              justifyContent={'center'}
              gap={[0, 0, '40px']}>
              <Flex mt={'15px'} justifyContent={'center'} width={'220px'}>
                <CircularProgress goalTime={friendQuery.data?.total || 1} spentTime={friendQuery.data?.total} />
              </Flex>
              <Flex
                flexDirection={['row', 'row', 'column']}
                justifyContent={['space-between', 'space-between', 'flex-start']}
                w={['100%', '100%', '160px']}
                flexShrink={0}
                mt={'16px'}>
                <Flex mb={'40px'}></Flex>
                <Flex flexDirection={['row', 'row', 'column']} gap={'12px'}>
                  <Stat
                    value={secondsToString(Math.round(friendQuery.data?.total || 0))}
                    title={t('overview.day.total')}
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Alert
        onConfirm={onRemove}
        isOpen={isConfirmDeleteOpen}
        title={t('friends.removeConfirmTitle', {name: friend?.name})}
        confirmText={t('friends.unfriend') as string}
        negativeConfirm
        onCancel={() => setIsConfirmDeleteOpen(false)}
      />
    </>
  )
}
